import React, {
  FunctionComponent,
  ReactNode,
  Fragment,
  useState,
  useEffect,
  useContext,
} from 'react';
import { BrandingContext } from 'components/app/BrandingProvider';
import Head from 'next/head';
import Header from '../shared/Header';
import Main from '../register/Main';

interface Props {
  title?: string;
  metaDescription?: string;
  children?: ReactNode;
}

const LoginLayout: FunctionComponent<Props> = ({
  title,
  metaDescription,
  children,
}: Props) => {
  const [pageTitle, setPageTitle] = useState<string>();
  const [internalBrandingLogo, setInternalBrandingLogo] = useState<any>();
  const { internalBrandingData } = useContext(BrandingContext);

  const setBrandingData = () => {
    const pageName = title ? `${title} | ` : '';
    setPageTitle(pageName + internalBrandingData?.displayName);
    setInternalBrandingLogo({
      logo: internalBrandingData?.logo,
      alt: internalBrandingData?.alt,
    });
  };

  useEffect(() => {
    setBrandingData();
  }, [internalBrandingData]);

  return (
    <Fragment>
      <Head>
        <title>{pageTitle}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
      </Head>
      <Header internalBrandingLogo={internalBrandingLogo} />
      <Main pt={['sm', 'md']}>{children}</Main>
    </Fragment>
  );
};

export default LoginLayout;
